
















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class PassengerImage extends Vue {
  @Prop() passengers: AnyObj[]

  get count() {
    return this.passengers.length
  }

  handleClick() {
    this.$emit('click', this.passengers[0])
  }
}
