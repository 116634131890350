



































































































import { Vue, Component, Model } from 'vue-property-decorator'

type Brand = { name: string; subbrands: Subbrand[] }
type Subbrand = { name: string; years: string[] }

@Component
export default class ParkingFilter extends Vue {
  @Model('change', { type: Object, default: () => [] }) value: Record<string, any>

  expanded = false
  areasValue: string[] = []
  dateValue = [this.$moment().startOf('day'), this.$moment().endOf('day')]
  brands: Brand[] = []
  colors = [
    '黑色',
    '蓝色',
    '棕色',
    '绿色',
    '灰色',
    '橙色',
    '粉色',
    '紫色',
    '红色',
    '银色',
    '白色',
    '黄色'
  ]

  private get syncedValue() {
    return this.value
  }

  private set syncedValue(value) {
    this.$emit('change', value)
  }

  get subbrands() {
    return this.brands.find(({ name }) => name === this.syncedValue.vehicleBrand)?.subbrands ?? []
  }

  get years() {
    return this.subbrands.find(({ name }) => name === this.syncedValue.vehicleSubBrand)?.years ?? []
  }

  async mounted() {
    this.fetchBrands()
  }

  async fetchBrands() {
    const { data } = await this.$api.static.getVehicleBrands()

    this.brands = data
  }

  handleFilter() {
    this.$emit('filter')
  }
}
