





























































































import { Vue, Component, Ref } from 'vue-property-decorator'
import PageTitle from '@/components/common/pageTitle.vue'
import ParkingFilter from './ParkingFilter.vue'
import PassengerImage from './PassengerImage.vue'
import PassengerDialog from './PassengerDialog.vue'
import PersonDetail from '@/components/dashboard/PersonDetail/index.vue'
import _ from 'lodash'
import { is } from '@/utils/helpers'

type Vehicle = AnyObj
type Person = AnyObj

@Component({
  components: { PageTitle, ParkingFilter, PassengerImage, PassengerDialog, PersonDetail }
})
export default class SmartParking extends Vue {
  @Ref() passengerDialog: PassengerDialog

  vehicles: Vehicle[] = []
  vehiclesTotal = 0
  loading = false
  pagination = { limit: 20, offset: 0 }
  filterData: AnyObj = {
    dateRange: [this.$moment().startOf('day'), this.$moment().endOf('day')]
  }
  personDetail: { visible: boolean; person?: AnyObj } = {
    visible: false
  }

  get rootConfig() {
    return this.$store.state.config?.root
  }

  get root() {
    return this.rootConfig ? this.rootConfig.id : this.$store.state.user.scope
  }

  get params() {
    const [startTime, endTime] = this.filterData.dateRange

    return {
      areaIds: [this.root],
      startTime,
      endTime,
      ...this.pagination,
      ..._.pickBy(_.omit(this.filterData, ['dateRange']), is.ava)
    }
  }

  mounted() {
    this.fetchVehicles()
  }

  async fetchVehicles() {
    this.loading = true

    const { data } = await this.$api.base.getVehicles(this.params)

    if (data.code === 0 && data.data) {
      this.vehicles = data.data
      this.vehiclesTotal = data.count
    }

    this.loading = false
  }

  getMainPassengers(persons: Person[]) {
    return persons.filter(({ vehiclePosition }) => vehiclePosition === 'frontLeft')
  }

  getSecondaryPassengers(persons: Person[]) {
    return persons.filter(({ vehiclePosition }) => vehiclePosition === 'frontRight')
  }

  getSuspectedPassengers(persons: Person[]) {
    return persons.filter(({ vehiclePosition }) => vehiclePosition === 'back')
  }

  simplifyTime(time: string) {
    return this.$moment(time).format('YYYY-MM-DD HH:mm:ss')
  }

  handleFilter() {
    this.fetchVehicles()
  }

  private handlePassengerClick(data: AnyObj) {
    this.personDetail = { visible: true, person: data }
  }

  handleDetailButtonClick(data: AnyObj) {
    this.passengerDialog.show(data)
  }

  pageSizeChange(size: number) {
    this.pagination.limit = size
    this.fetchVehicles()
  }

  pageChange(page: number) {
    this.pagination.offset = (page - 1) * this.pagination.limit
    this.fetchVehicles()
  }
}
