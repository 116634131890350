












































import { Vue, Component } from 'vue-property-decorator'
import PersonDetail from '@/components/dashboard/PersonDetail/index.vue'

@Component({ components: { PersonDetail } })
export default class PassengerDialog extends Vue {
  private data: AnyObj = {}
  private visible = false
  private fullPanoramaVisible = false
  private panoramaLoading = true
  private personDetail: { visible: boolean; person?: AnyObj } = {
    visible: false
  }

  private get passengers() {
    return this.data.persons
      ? this.data.persons.filter(({ vehiclePosition }: AnyObj) => vehiclePosition === 'back')
      : []
  }

  private get vehicle() {
    return this.data.vehicle ?? {}
  }

  public show(data: AnyObj) {
    this.data = data
    this.visible = true
    this.panoramaLoading = true
  }

  private close() {
    this.data = {}
    this.visible = false
    this.panoramaLoading = false
  }

  private showFullImage() {
    this.fullPanoramaVisible = true
  }

  private handlePanoramaLoad() {
    this.panoramaLoading = false
  }

  private handlePassengerClick(data: AnyObj) {
    this.personDetail = { visible: true, person: data }
  }
}
